import { useState } from 'react'

import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { SwiperSlide } from 'swiper/react'

import { useSubscriptionNavigation } from '~/lib/utils/navigation'

import {
  SecondaryActionButton,
  TertiaryActionButton,
} from '~/components/elements/buttons/ActionButton'
import GetStartedButton from '~/components/elements/buttons/GetStartedButton'
import TestimonialCard from '~/components/elements/cards/TestimonialCard'
import { TitleLabelSecondary } from '~/components/elements/labels/TitleLabels'
import BasicSwiper from '~/components/elements/swipers/BasicSwiper'
import { CategorySwiper } from '~/components/elements/swipers/CategorySwiper'
import HeroSection from '~/components/sections/HeroSection'
import LogoSection from '~/components/sections/LogoSection'
import SubscriptionUpsellSection from '~/components/sections/SubscriptionUpsellSection'
import BackgroundVideoPlayerWithModal from '~/components/video/BackgroundVideoPlayerWithModal'
import ModalVideoPlayer from '~/components/video/ModalVideoPlayer'
import Footer from '../components/nav/footer'
import Navbar from '../components/nav/navbar'

import categorySwiperCardsData from '~/lib/json/category-swiper-cards.json'

export default function Homepage() {
  const router = useRouter()
  const { showSubscribePage } = useSubscriptionNavigation()
  let [isTrailerOpen, setIsTrailerOpen] = useState(false)

  return (
    <div className="dark bg-black-alt font-sans-new text-white">
      <Navbar />

      <HeroSection
        supertitleLabel="Hello, Parents"
        title="Kids don't come with a manual, but this is pretty close."
        largeTitle={true}
        backgroundImageURL="/images/home/hero.jpg"
        backgroundImageAlt="Background. Woman holding baby and watching video on tablet."
      >
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="flex-grow whitespace-pre-wrap md:max-w-[280px]">
            <GetStartedButton
              title="Get Started"
              area="Home page hero section"
              onClick={() => {
                showSubscribePage()
              }}
            />
          </div>
          <ModalVideoPlayer
            open={isTrailerOpen}
            setIsOpen={setIsTrailerOpen}
            url="https://stream.mux.com/Ic100kdpmHp007hIcfrQXqrS9FJETnuw01S5EVMTy5HKx00.m3u8"
          />
          <div className="flex-grow md:max-w-[280px]">
            <TertiaryActionButton
              onClick={() => {
                setIsTrailerOpen(true)
              }}
              title={'▶   Preview'}
            />
          </div>
        </div>
        <span className="top-8 mt-5 block text-center text-xs font-medium text-white md:text-left md:text-sm">
          Starting at $12.95/month (Billed Annually)
        </span>
      </HeroSection>
      <LogoSection />

      {/* featured categories */}
      <div className="container py-10">
        <div className="pb-3 md:w-[70%]">
          <TitleLabelSecondary largeFont={true}>
            Expert-led classes from pregnancy through parenthood.
          </TitleLabelSecondary>
        </div>

        <CategorySwiper cards={categorySwiperCardsData.cards} />

        <div className="md:max-w-[300px]">
          <SecondaryActionButton
            title="Browse All Classes"
            onClick={() => {
              router.push('/classes')
            }}
          />
        </div>
      </div>

      <SubscriptionUpsellSection />

      {/* Testimonials */}
      <div className=" bg-black-alt px-3 py-16 md:px-16">
        <div className="container max-w-[800px]">
          <BasicSwiper numVisible={1} autoHeight={true}>
            <SwiperSlide>
              <TestimonialCard stars={5} author="Kara, 1st time expectant parent" large_text={true}>
                It&apos;s great because it&apos;s flexible, and you can do it on your schedule, and
                you don&apos;t have to actually leave your house or drive somewhere. And it&apos;s
                nice, too, because you can go back and re-look at content, and I really appreciated
                the takeaways and the clips.
              </TestimonialCard>
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard stars={5} author="Tina, expectant mom" large_text={true}>
                I love this website! The content is in meaningful, bite-sized pieces, which is
                perfect for busy moms-to-be. In my opinion, this easy to follow along video format
                with side notes beats having to wade through chapters and chapters of a book in
                which it becomes impossible to not only remember, but also to later easily access
                the information that is relevant to you.
              </TestimonialCard>
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialCard stars={5} author="Molly, expectant parent" large_text={true}>
                I feel so much more empowered now with our first child on the way after taking this
                course! There is so much I didn&apos;t know to even ask about or that I needed to
                learn!
              </TestimonialCard>
            </SwiperSlide>
          </BasicSwiper>
        </div>
      </div>

      {/* 'Learn more' video section */}
      <BackgroundVideoPlayerWithModal
        backgroundVideoUrl="https://stream.mux.com/02HKqXKqzsvu4FvsSp8Reux01mOxem2e00P02HVw02mgMXPA.m3u8"
        modalVideoUrl="https://stream.mux.com/Ic100kdpmHp007hIcfrQXqrS9FJETnuw01S5EVMTy5HKx00.m3u8"
        title="Learn everything you need to know — and what others wish they had known."
      ></BackgroundVideoPlayerWithModal>

      {/* Donations */}
      <section className="relative flex min-h-[400px] w-full flex-col bg-white md:justify-center">
        <div className="-z-1 absolute bottom-0 left-0 right-0 top-0">
          <Image
            fill
            className="object-contain object-bottom md:object-cover md:object-right"
            src="/images/home/donate.jpg"
            alt="Photo of a hand placing a heart-shaped cutout into a box"
          />
          <div className="absolute h-full w-full bg-black/[.25]"></div>
          <div className="absolute h-[80%] w-full bg-gradient-to-b from-black-alt from-35% to-transparent md:h-full md:w-[70%] md:bg-gradient-to-r md:from-0%" />
        </div>
        <div className="container relative my-0 flex h-full flex-row py-0 md:my-24 md:p-0 md:px-24">
          <div className="flex max-w-[95%] flex-col justify-center text-white md:max-w-400 md:items-start">
            <h2 className="whitespace-pre font-super-new text-xl font-normal uppercase md:text-3xl">
              {`Buy a membership,\ndonate a membership`}
            </h2>
            <div className="pt-4 md:pt-8 md:text-3xl">
              We believe all parents should have access to the best possible education. For every
              membership purchased, Tinyhood donates a membership to those in need.
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}
